import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { AxisBottom, AxisLeft } from '@visx/axis';
import { ParentSize } from '@visx/responsive';
import { scaleLinear, scaleTime } from '@visx/scale';
import { Group } from '@visx/group';
import { GridColumns, GridRows } from '@visx/grid';
import { LinePath, Circle } from '@visx/shape';
import { max, bisect } from 'd3-array';

import { getY, tickFormatFactory, calculateXAxisTickValues } from '../../utils/chartHelpers';

import PropTypes from 'prop-types';
import { TooltipWithBounds, useTooltip, defaultStyles } from '@visx/tooltip';
import { localPoint } from '@visx/event';
import dayjs from 'dayjs';
import { DATE_TIME_FORMAT_MONTH_DAY_HOUR_MINUTE } from '../../utils/dates';
import { gql, useQuery } from '@apollo/client';
import { useAtomValue } from 'jotai';
import { algorithmVersionAtom } from '../../utils/jotaiAtoms';

const TOOLTIP_STYLES = {
  ...defaultStyles,
  borderRadius: '8px',
  backgroundColor: 'rgba(40, 40, 40, 0.8)',
  color: 'white',
  border: '1px solid #E6E6E6',
  padding: '1rem',
};

function CoefficientChart({ startDate = 1706625546, endDate = 1722350354, feed_line_id = null, loading }) {
  const { tooltipData, tooltipLeft = 0, tooltipTop = 0, tooltipOpen, showTooltip, hideTooltip } = useTooltip();
  const [start, setStart] = useState(startDate);
  const [end, setEnd] = useState(endDate);
  const chartMargin = { top: 10, bottom: 50, left: 30, right: 30 };
  const [zoomStartPoint, setZoomStartPoint] = useState(null);
  const [zoomEndPoint, setZoomEndPoint] = useState(null);
  const chartSeries = [];
  const algorithmVersion = useAtomValue(algorithmVersionAtom);

  const FEED_LINE_COEF = gql`
    query FeedLineCoefficientQuery($fl_id: uuid!, $alg: String) {
      feed_line_coefficient(
        order_by: { started_at: asc }
        where: { feed_line_id: { _eq: $fl_id }, deleted_at: { _is_null: true }, algorithm_version: { _eq: $alg } }
      ) {
        started_at
        ended_at
        live_ended_at
        coefficients
      }
    }
  `;
  const { data, error } = useQuery(FEED_LINE_COEF, {
    variables: {
      fl_id: feed_line_id,
      alg: algorithmVersion,
    },
    skip: !feed_line_id,
  });
  if (error) throw error;

  const feed_line_coefficients = data?.feed_line_coefficient;

  useEffect(() => {
    setStart(startDate);
    setEnd(endDate);
  }, [startDate, endDate]);

  /**
   * Given two points and a timestamp between them, return the value that would correspond to that timestamp.
   *
   * @param p0 The first point to lerp between.
   * @param p1 The second point to lerp between.
   * @param timestamp The point between p0 and p1 to calculate the value for.
   * @returns The value corresponding to the given timestamp between p0 and p1.
   */
  const lerpToTimestamp = useCallback(
    /**
     * @param {Object} p0
     * @param {number} p0.value
     * @param {number} p0.timestamp
     * @param {Object} p1
     * @param {number} p1.value
     * @param {number} p1.timestamp
     * @param {number} timestamp
     */
    (p0, p1, timestamp) => {
      // Guard against NaN slope/vertical line by returning the initial p0 value.
      if (p0.timestamp === p1.timestamp) return p0.value;
      const slope = (p1.value - p0.value) / (p1.timestamp - p0.timestamp);
      return p0.value + (timestamp - p0.timestamp) * slope;
    },
    [],
  );

  return (
    <ParentSize debounceTime={10}>
      {(parent) => {
        const xMax = parent.width - chartMargin.left - chartMargin.right;
        const yMax = parent.height - chartMargin.top - chartMargin.bottom;

        const xAxisTickFormat = tickFormatFactory(start, end);
        const xAxisTicks = calculateXAxisTickValues(start, end);

        const xScale = useCallback(
          scaleTime({
            domain: [start, end],
            range: [0, xMax],
          }),
          [start, end, xMax],
        );
        const yScale = useCallback(
          scaleLinear({
            domain: [0.5, 1.5],
            range: [yMax, 0],
          }),
          [yMax],
        );

        const formatDateTooltip = useCallback((hoveredDate) => {
          return dayjs.unix(hoveredDate).format(DATE_TIME_FORMAT_MONTH_DAY_HOUR_MINUTE);
        }, []);

        /**
         * Given a timestamp, find points that intersect with each chart line.
         * Points are generated by interpolation.
         *
         * @param timestamp The timestamp to intersect with each chart line.
         *
         * @returns An array of point objects with value, timestamp, and colour keys.
         */
        const getInterceptPointsForTimestamp = useCallback(
          /**
           * @param {number} timestamp
           * @returns {Array<{value: number, timestamp: number, colour: string, hidden: boolean}>}
           */
          (timestamp) =>
            // Note: we are NOT filtering here because we care about maintaining the indexes for parallel arrays when hiding
            // Instead we will simply pass along the hidden property
            chartSeries
              .map((series) => {
                let point = {};
                const index = bisect(series.x, new Date(timestamp * 1000));
                if (series.x[index - 1] && series.x[index]) {
                  const point0 = { timestamp: series.x[index - 1].getTime() / 1000, value: series.y[index - 1] };
                  const point1 = { timestamp: series.x[index].getTime() / 1000, value: series.y[index] };
                  point = point0 || point1;
                  if (point0 && point1) {
                    // If both points exist, lerp between them to estimate the value at the given timestamp
                    const value = lerpToTimestamp(point0, point1, timestamp);
                    point = { timestamp, value };
                  }
                }
                return { ...point, colour: series.line.color };
              })
              .filter((series) => series?.timestamp),
          [chartSeries],
        );

        const handleTooltip = useCallback(
          (mouseEvent) => {
            // Gets the position of the mouse cursor.
            const point = localPoint(mouseEvent);
            const xCoord = point.x - chartMargin.left;

            // If the mouse pointer is off the chart, we don't want to display any tool tips.
            if (xCoord < 0 || xCoord > xMax) return;

            const xCoordTimestamp = xScale.invert(xCoord).valueOf();

            // Gets the nearest intercept point to the current mouse X position for each chartLineData entry.
            // Array of objects like { timestamp, value, colour, hidden }

            const lineIntercepts = getInterceptPointsForTimestamp(xCoordTimestamp);

            if (lineIntercepts) {
              const xCoordDate = xScale.invert(xCoord);
              const maxYIntercept = max(lineIntercepts, getY);
              const closestCoef = feed_line_coefficients?.reduce((curr_fl_c, min) => {
                if (Math.abs(curr_fl_c.started_at - xCoordDate) < Math.abs(min.started_at - xCoordDate))
                  return curr_fl_c;
                else return min;
              }, feed_line_coefficients[0]);
              const formattedDate = formatDateTooltip(closestCoef.started_at);

              showTooltip({
                tooltipLeft: xCoord,
                tooltipTop: yScale(maxYIntercept),
                tooltipData: {
                  value: closestCoef.coefficients.linearCorrection,
                  started_at: closestCoef.started_at,
                  displayedDate: formattedDate,
                },
              });
            }
          },
          [chartSeries, xMax, xScale, yScale, getInterceptPointsForTimestamp],
        );

        const linePaths = useMemo(() => {
          if (!feed_line_coefficients) return [];

          const x = feed_line_coefficients.map((co) => co.started_at);
          const y = feed_line_coefficients.map((co) => co?.coefficients?.linearCorrection);
          return [
            <LinePath
              data={x}
              x={(value) => {
                return xScale(value);
              }}
              y={(value, index) => {
                return yScale(y[index]);
              }}
              stroke={'#000000'}
              strokeWidth={2}
              strokeOpacity={1}
              key={'ChartLinePath'}
              className={'ChartLinePath'}
            />,
          ];
        }, [xScale, yScale, feed_line_coefficients]);

        const markers = useMemo(() => {
          if (!feed_line_coefficients) return [];

          return feed_line_coefficients.map((co) => {
            return (
              <circle
                r={3}
                key={co.started_at}
                cx={xScale(co.started_at)}
                cy={yScale(co?.coefficients?.linearCorrection)}
              />
            );
          });
        }, [xScale, yScale, feed_line_coefficients]);

        const svgCursor = loading ? 'wait' : zoomStartPoint && zoomEndPoint ? 'zoom-in' : 'crosshair';

        return (
          <div className="EventChart" key={'EventChart'}>
            <svg
              className="EventChart-chartSvg"
              style={{
                height: parent.height,
                width: parent.width,
                userSelect: 'none',
                cursor: svgCursor,
              }}
              onTouchStart={handleTooltip}
              onTouchMove={handleTooltip}
              onMouseMove={(event) => {
                handleTooltip(event);
                if (zoomStartPoint) {
                  setZoomEndPoint(localPoint(event).x);
                }
              }}
              onMouseLeave={hideTooltip}
              onMouseDown={(event) => {
                setZoomStartPoint(localPoint(event).x);
              }}
              onMouseUp={() => {
                const startPoint = zoomStartPoint > zoomEndPoint ? zoomEndPoint : zoomStartPoint;
                const endPoint = zoomStartPoint <= zoomEndPoint ? zoomEndPoint : zoomStartPoint;

                if (startPoint && endPoint && 20 < endPoint - startPoint) {
                  setStart(xScale.invert(startPoint - chartMargin.left));
                  setEnd(xScale.invert(endPoint - chartMargin.left));
                }

                setZoomStartPoint(null);
                setZoomEndPoint(null);
              }}
              onDoubleClick={() => {
                setStart(startDate);
                setEnd(endDate);
              }}
            >
              <Group
                left={chartMargin.left}
                top={chartMargin.top}
                bottom={chartMargin.bottom}
                right={chartMargin.right}
              >
                <defs>
                  <clipPath id="eventInnerChartHorizontalBoundary">
                    <rect x={0} y={0} width={xMax} height={yMax} />
                  </clipPath>
                </defs>
                <GridColumns
                  scale={xScale}
                  width={xMax}
                  height={yMax}
                  stroke="#D9DCE1"
                  strokeWidth={2}
                  tickValues={xAxisTicks}
                />

                <AxisBottom
                  top={yMax}
                  scale={xScale}
                  hideTicks
                  stroke="#D9DCE1"
                  tickFormat={xAxisTickFormat}
                  tickValues={xAxisTicks}
                />

                <GridRows scale={yScale} width={xMax} height={yMax} stroke="#D9DCE1" strokeWidth={2} numTicks={5} />

                <AxisLeft top={0} scale={yScale} hideTicks stroke="#D9DCE1" numTicks={5} />

                {zoomStartPoint && zoomEndPoint && (
                  <rect
                    height={yMax}
                    y={0}
                    x={(zoomStartPoint < zoomEndPoint ? zoomStartPoint : zoomEndPoint) - chartMargin.left}
                    width={Math.abs(zoomEndPoint - zoomStartPoint)}
                    fill={`#00000022`}
                  ></rect>
                )}

                {
                  // Create the vertical dashed line and circular intercept markers that accompany the tooltip.
                  // Do not show if someone is interacting with the projection brushes.
                  tooltipOpen && (
                    <g pointerEvents="none">
                      <line
                        stroke="#000"
                        strokeWidth="1"
                        x1={tooltipLeft}
                        x2={tooltipLeft}
                        y1={0}
                        y2={yMax}
                        strokeDasharray={(5, 5)}
                      />
                      <Circle
                        key={0}
                        className="EventChart-chartInterceptCircleSvg"
                        cx={xScale(tooltipData.started_at)}
                        cy={yScale(tooltipData.value)}
                        r={7}
                        stroke={'grey'}
                      />
                      ,
                    </g>
                  )
                }
                {markers}
                {linePaths}
              </Group>
            </svg>
            {
              // Create the tooltip unless someone is interacting with the projection brushes.
              tooltipOpen && (
                <TooltipWithBounds top={tooltipTop - 50} left={tooltipLeft + 35} style={TOOLTIP_STYLES}>
                  <div className={`EventChart-tooltipInfo`}>
                    {
                      <>
                        <p className="EventChart-tooltipInfoHeader">{tooltipData.displayedDate}</p>
                        <div className="EventChart-tooltipInfo-dataholder">
                          <React.Fragment key={0}>
                            <div className="EventChart-tooltipInfo-dataholder-mass">
                              <div className={`EventChart-tooltipCircle`} style={{ backgroundColor: 'grey' }} />
                              <span className="EventChart-tooltipInfo-dataholder-text">{tooltipData.value}</span>
                            </div>
                          </React.Fragment>{' '}
                        </div>
                      </>
                    }
                  </div>
                </TooltipWithBounds>
              )
            }
          </div>
        );
      }}
    </ParentSize>
  );
}

CoefficientChart.propTypes = {
  startDate: PropTypes.number,
  endDate: PropTypes.number,
  feed_line_id: PropTypes.string,
  loading: PropTypes.bool,
};

export default CoefficientChart;
