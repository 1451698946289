import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { atom, useAtom, useAtomValue } from 'jotai';
import cloneDeep from 'lodash/cloneDeep';
import dayjs from 'dayjs';
import { FaultCodes, getFaultName } from '@norimaconsulting/fault-codes';
import PropTypes from 'prop-types';

import QuickExportButton from '../../pages/BarnConsumptionTab/QuickExportButton';
import DateRangePicker from '../../organisms/DateRangePicker';
import EventAccordion from '../../molecules/EventAccordion';
import EventChart from '../../molecules/EventChart';
import FeedFloDropDown from '../../atoms/FeedFloDropDown';
import Button from '../../atoms/Button';
import SectionalButton from '../../atoms/SectionalButton/SectionalButton';
import {
  BarChartIconSVG,
  ClockIcon,
  FeedChangeIconSVG,
  InterventionIconSVG,
  ObservationIconSVG,
  PigIconSVG,
  StockChangeIconSVG,
  WarningIcon,
} from '../../atoms/Icons';
import LoadingSkeleton from '../../atoms/LoadingSkeleton';

import {
  ADD_FAULT_COMMENT_GQL,
  FAULTS_GQL,
  CONSUMPTION_BARN_CUMULATIVE,
  CONSUMPTION_BARN_INTERVAL,
  CONSUMPTION_FEEDLINE_CUMULATIVE,
  CONSUMPTION_FEEDLINE_INTERVAL,
} from './queries';
import WebAppContext from '../../utils/webAppContext';
import useDefaultDateRange from '../../utils/hooks/useDefaultDateRange';
import { convertGramsToSmallUnits, weightLargeUnitLabel, convertGramsToLargeUnits } from '../../utils/unitConversion';
import { ConsumptionTabCustomEventType, ConsumptionTabEventCategory } from '../../utils/enums';
import { DATE_TIME_FORMAT_MONTH_DAY_HOUR_MINUTE } from '../../utils/dates';
import useFeature from '../../utils/hooks/useFeature';
import useUser from '../../utils/hooks/useUser';
import { algorithmVersionAtom } from '../../utils/jotaiAtoms';
import { censorTypes, useCensor } from '../../utils/hooks/useCensor';

import iconColours from './ConsumptionColours.module.scss';
import './ConsumptionDisplay.scss';

// The default font size is 16 pixels
const DEFAULT_FONT_SIZE = 16;
const CHART_MARGIN = { top: 20, right: 25, bottom: 65, left: 35 };

// The list of event categories for the event accordion filter to offer.
const EVENT_CATEGORIES = [
  { id: ConsumptionTabEventCategory.ChangeInConsumption, name: 'Health Flag' },
  { id: ConsumptionTabEventCategory.CustomEvent, name: 'Custom Event' },
  { id: ConsumptionTabEventCategory.EmptyPipe, name: 'Empty Pipe' },
  { id: ConsumptionTabEventCategory.InactiveAuger, name: 'Inactive Auger' },
];

// Fill and stroke colours for each event category.
const EVENT_COLOURS = Object.freeze({
  [ConsumptionTabEventCategory.CustomEvent]: {
    fill: iconColours.customEventFill,
    stroke: iconColours.customEventStroke,
  },
  [ConsumptionTabEventCategory.ChangeInConsumption]: {
    fill: iconColours.changeInConsumptionFill,
    stroke: iconColours.changeInConsumptionStroke,
  },
  [ConsumptionTabEventCategory.InactiveAuger]: {
    fill: iconColours.inactiveAugerFill,
    stroke: iconColours.inactiveAugerStroke,
  },
  [ConsumptionTabEventCategory.EmptyPipe]: { fill: iconColours.emptyPipeFill, stroke: iconColours.emptyPipeStroke },
});

// All potential viewing options.
const VIEW_OPTIONS = Object.freeze({
  FullBarn: 'Full Barn',
  FeedLines: 'Feed Lines',
  Rooms: 'Rooms',
});

// The order that viewing options should appear in the drop-down list.
const VIEW_OPTION_ORDER = Object.freeze([
  VIEW_OPTIONS.FullBarn,
  VIEW_OPTIONS.FeedLines,
  // TODO: Uncomment the following once we know what to do about rooms.
  // VIEW_OPTIONS.Rooms,
]);

const VIEW_OPTION_LIST = Object.freeze(
  VIEW_OPTION_ORDER.map((viewOption, index) => {
    return { name: viewOption, id: index };
  }),
);

const DEFAULT_VIEW_OPTION = VIEW_OPTIONS.FeedLines;

// The colours that line plots will be assigned in order.
// If there are more line plots than colours then colours will repeat from the beginning.
const LINE_COLOUR_ROTATION = Object.freeze([
  '#26AF5F',
  '#6AE09B',
  '#65C9DA',
  '#CFD74D',
  '#2676af',
  '#2631af',
  '#5e26af',
  '#a326af',
  '#af2676',
  '#af2631',
  '#af5e26',
  '#afa326',
  '#76af26',
  '#31af26',
]);

// A max fault duration used to filter out invalid faults that never ended or got cleaned up.
const MAX_DURATION_IN_SECONDS = 1209600; // 2 weeks in seconds
// A minimum fault duration used to filter out quickly-resolved faults that wouldn't be notified for.
const MIN_DURATION_IN_SECONDS = 43200; // 12 hours in seconds
const ALL_FAULTS_MIN_DURATION_IN_SECONDS = 300; // 5 minutes in seconds

export const inspectionWindowActiveAtom = atom(false);

/**
 * Given an event category, determine the appropriate JSX to represent it.
 *
 * @param {string} category The category as a member of the ConsumptionTabEventCategory enum.
 * @param {string} customEventType If the category is 'CustomEvent', also pass a custom event category.
 
 * @returns The appropriate icon as JSX.
 */
function makeEventIcon(category, customEventType, dashed = false) {
  let icon;
  let containerClass;
  if (category === ConsumptionTabEventCategory.EmptyPipe) {
    containerClass = 'ConsumptionDisplay-eventIconContainer--emptyPipe';
    icon = <WarningIcon className="ConsumptionDisplay-eventIcon ConsumptionDisplay-emptyPipeIcon" />;
  } else if (category === ConsumptionTabEventCategory.InactiveAuger) {
    containerClass = 'ConsumptionDisplay-eventIconContainer--inactiveAuger';
    icon = <ClockIcon className="ConsumptionDisplay-eventIcon ConsumptionDisplay-inactiveAugerIcon" />;
  } else if (category === ConsumptionTabEventCategory.ChangeInConsumption) {
    containerClass = 'ConsumptionDisplay-eventIconContainer--changeInConsumption';
    icon = <BarChartIconSVG className="ConsumptionDisplay-eventIcon ConsumptionDisplay-changeInConsumptionIcon" />;
  } else if (category === ConsumptionTabEventCategory.CustomEvent) {
    containerClass = 'ConsumptionDisplay-eventIconContainer--customEvent';
    if (customEventType === ConsumptionTabCustomEventType.FeedChange) {
      icon = <FeedChangeIconSVG className="ConsumptionDisplay-eventIcon ConsumptionDisplay-customEventIcon" />;
    } else if (customEventType === ConsumptionTabCustomEventType.Intervention) {
      icon = <InterventionIconSVG className="ConsumptionDisplay-eventIcon ConsumptionDisplay-customEventIcon" />;
    } else if (customEventType === ConsumptionTabCustomEventType.Observation) {
      icon = (
        <ObservationIconSVG className="ConsumptionDisplay-eventIcon ConsumptionDisplay-customEventIcon--observation" />
      );
    } else if (customEventType === ConsumptionTabCustomEventType.Other) {
      icon = <PigIconSVG className="ConsumptionDisplay-eventIcon ConsumptionDisplay-customEventIcon--other" />;
    } else if (customEventType === ConsumptionTabCustomEventType.StockChange) {
      icon = <StockChangeIconSVG className="ConsumptionDisplay-eventIcon ConsumptionDisplay-customEventIcon" />;
    }
  }

  return (
    icon && (
      <div
        className={`ConsumptionDisplay-eventIconContainer ${containerClass} ${
          dashed ? 'ConsumptionDisplay-eventIconContainer--dashed' : ''
        }`}
      >
        {icon}
      </div>
    )
  );
}

/**
 * Make the content component of an event panel.
 *
 * @param {string} title The title for this event.
 * @param {number} startedAt The start timestamp in seconds.
 * @param {number} endedAt The end timestamp in seconds. Null if ongoing.
 * @param {number} nofeedSeconds Seconds of nofeed. 0 values will not be shown.
 * @param {boolean} expanded Whether the panel is expanded.
 *
 * @returns JSX.
 */
function makeEventContent(title = '', startedAt = 0, endedAt = null, nofeedSeconds = 0, expanded = false) {
  const duration = endedAt
    ? dayjs.duration(dayjs.tz(1000 * endedAt).diff(dayjs.tz(1000 * startedAt))).humanize()
    : 'ongoing';
  const nofeedHours = nofeedSeconds > 0 && Math.round(dayjs.duration(nofeedSeconds, 'seconds').asHours());
  return (
    <div className="ConsumptionDisplay-eventContent">
      <p className="ConsumptionDisplay-eventTitleText">{title}</p>

      {expanded ? (
        <span className="ConsumptionDisplay-eventDateText">
          {dayjs.tz(1000 * startedAt).format(DATE_TIME_FORMAT_MONTH_DAY_HOUR_MINUTE)}
          {endedAt && <br />}
          {endedAt && dayjs.tz(1000 * endedAt).format(DATE_TIME_FORMAT_MONTH_DAY_HOUR_MINUTE)}
        </span>
      ) : (
        <span className="ConsumptionDisplay-eventDateText">
          {dayjs.tz(1000 * startedAt).format(DATE_TIME_FORMAT_MONTH_DAY_HOUR_MINUTE)}
        </span>
      )}
      {duration}
      {nofeedHours ? (
        <span className="ConsumptionDisplay-eventTitleText">
          {nofeedHours === 0 ? '<1' : nofeedHours}
          {nofeedHours <= 1 ? ' hr of ' : ' hrs of '}
          NoFeed
        </span>
      ) : null}
    </div>
  );
}

function ConsumptionDisplay({ barnId: paramBarnId, animalGroupDateRange = null, openFaultIds = null }) {
  const algorithmVersion = useAtomValue(algorithmVersionAtom);
  const now = useMemo(() => dayjs(), []);
  const barnId = useMemo(() => paramBarnId, [paramBarnId]);

  const { user } = useUser();
  const { active: showAllFaultsFlag } = useFeature('SHOW_ALL_FAULTS');

  const { isMetric } = useContext(WebAppContext);
  const { censor } = useCensor();
  const defaultDateRange = useDefaultDateRange(barnId);

  let barnName = '';
  const [dateRange, setDateRange] = useState(animalGroupDateRange || defaultDateRange);
  const [dateRangePickerVisible, setDateRangePickerVisible] = useState(false);

  const [selectedView, setSelectedView] = useState(DEFAULT_VIEW_OPTION);
  const [cumulative, setCumulative] = useState(true);

  // A dictionary to track which feed lines are hidden from the chart.
  const [hiddenChartLines, setHiddenChartLines] = useState({});

  // A dictionary to track which events are expanded in the event accordion.
  const [expandedEvents, setExpandedEvents] = useState({});

  const [remPixels, setRemPixels] = useState(DEFAULT_FONT_SIZE);

  const [projectionMode, setProjectionMode] = useAtom(inspectionWindowActiveAtom);

  // State used for the event accordion
  // A dictionary of selected categories.
  // Initialized to 'true' for each of the category ids passed to the 'categories' prop.
  const [visibleCategories, setVisibleCategories] = useState(
    EVENT_CATEGORIES.reduce((filters, category) => {
      filters[category.id] = true;
      return filters;
    }, {}),
  );
  const [scrollToEventId, setScrollToEventId] = useState(null);
  const [saveFaultComment] = useMutation(ADD_FAULT_COMMENT_GQL);

  const startInSeconds = dateRange.from.unix() || 0;
  const endInSeconds = dateRange.to.unix() || 0;

  const chartHeight = 56 * remPixels;

  useEffect(() => {
    // Load the computed value for rems once the page has fully loaded
    setRemPixels(parseFloat(getComputedStyle(document.documentElement).fontSize));
  }, []);

  // This effect updates the date picker and chart range when the animal group loads or switches.
  useEffect(() => {
    if (animalGroupDateRange) {
      setDateRange(animalGroupDateRange);
    }
  }, [animalGroupDateRange]);

  const {
    error: errorFaultQuery,
    loading: loadingFaultQuery,
    data: dataFaultQuery,
  } = useQuery(FAULTS_GQL, {
    variables: {
      barnId,
      endRangeTimestamp: now.unix() < endInSeconds ? now.unix() : endInSeconds,
      faultCodes: [
        FaultCodes.EMPTY_PIPE,
        FaultCodes.INACTIVE_AUGER,
        // Hide until we fix the thresholds
        // FaultCodes.SUDDEN_CONSUMPTION_DROP,
        // FaultCodes.CONSUMPTION_TRENDING_DOWN,
      ],
      maxDuration: MAX_DURATION_IN_SECONDS,
      minDuration: showAllFaultsFlag ? ALL_FAULTS_MIN_DURATION_IN_SECONDS : MIN_DURATION_IN_SECONDS,
      startRangeTimestamp: startInSeconds,
    },
  });

  if (errorFaultQuery) throw errorFaultQuery;

  useEffect(() => {
    if (dataFaultQuery?.feed_line) {
      const expandedEvents = dataFaultQuery.feed_line.reduce((arr, { device_assignments }) => {
        if (device_assignments) {
          const faults = device_assignments.reduce((innerArr, deviceAssignment) => {
            if (deviceAssignment?.device?.faults) {
              const activeFaults = deviceAssignment.device.faults
                .filter((fault) => fault.ended_at === null)
                .map((fault) => fault.id);
              return innerArr.concat(activeFaults);
            }
            return innerArr;
          }, []);
          return arr.concat(faults);
        }
        return arr;
      }, []);
      setExpandedEvents(
        expandedEvents.reduce((obj, id) => {
          obj[id] = true;
          return obj;
        }, {}),
      );
    }
  }, [dataFaultQuery]);

  let consumption_query = CONSUMPTION_FEEDLINE_CUMULATIVE;

  if (cumulative) {
    if (selectedView === VIEW_OPTIONS.FullBarn) {
      consumption_query = CONSUMPTION_BARN_CUMULATIVE;
    } else {
      consumption_query = CONSUMPTION_FEEDLINE_CUMULATIVE;
    }
  } else {
    if (selectedView === VIEW_OPTIONS.FullBarn) {
      consumption_query = CONSUMPTION_BARN_INTERVAL;
    } else {
      consumption_query = CONSUMPTION_FEEDLINE_INTERVAL;
    }
  }
  const {
    error: errorConsumptionQuery,
    loading: loadingConsumptionQuery,
    data: dataConsumptionQuery,
  } = useQuery(consumption_query, {
    variables: {
      barnId,
      start: startInSeconds,
      end: endInSeconds,
      interval: cumulative ? (endInSeconds - startInSeconds > 3 * 24 * 60 * 60 ? 'hour' : 'minute') : 'day',
      alg: algorithmVersion,
      time_zone: user.timezone,
    },
    skip: !algorithmVersion,
  });
  if (errorConsumptionQuery) throw errorConsumptionQuery;

  let newChartLines = [];
  let feedUsage = 0;

  const apiDataToChartData = (data) => {
    return { timestamp: data.start_timestamp, value: convertGramsToSmallUnits(isMetric, data.total_consumption) };
  };

  if (!loadingConsumptionQuery) {
    if (cumulative) {
      if (selectedView === VIEW_OPTIONS.FullBarn) {
        newChartLines = [
          {
            linePlot: dataConsumptionQuery?.barn[0].feed_frame_summaries_cumulative
              .filter((x) => x.start_timestamp < now.unix())
              .map(apiDataToChartData),
            label: 'Barn',
            colour: LINE_COLOUR_ROTATION[0],
            link: {
              pathname: `/b/${barnId}`,
              state: { start: startInSeconds, end: endInSeconds },
            },
            hidden: false,
          },
        ];
        feedUsage = dataConsumptionQuery?.barn[0].feed_frame_summaries_cumulative.at(-1).total_consumption;
      } else {
        newChartLines = dataConsumptionQuery?.barn[0]?.feed_lines.map((fl, index) => {
          return {
            linePlot: fl.feed_frame_summaries_cumulative
              .filter((x) => x.start_timestamp < now.unix())
              .map(apiDataToChartData),
            label: fl.name,
            colour: LINE_COLOUR_ROTATION[index % LINE_COLOUR_ROTATION.length],
            link: {
              pathname: `/b/${barnId}/line/${fl.id}`,
              state: { start: startInSeconds, end: endInSeconds },
            },
            hidden: hiddenChartLines[fl.id],
            onHideClick: (hidden) => hideChartLine(fl.id, hidden),
          };
        });
        feedUsage = dataConsumptionQuery?.barn[0]?.feed_lines.reduce(
          (sum, item) =>
            sum + (hiddenChartLines[item.id] ? 0 : item.feed_frame_summaries_cumulative.at(-1)?.total_consumption),
          0,
        );
      }
    } else {
      if (selectedView === VIEW_OPTIONS.FullBarn) {
        newChartLines = [
          {
            linePlot: dataConsumptionQuery?.barn[0].feed_frame_summaries.map(apiDataToChartData),
            label: 'Barn',
            colour: LINE_COLOUR_ROTATION[0],
            link: {
              pathname: `/b/${barnId}`,
              state: { start: startInSeconds, end: endInSeconds },
            },
            hidden: false,
          },
        ];
        feedUsage = dataConsumptionQuery?.barn[0]?.feed_frame_summaries.reduce(
          (sum, item) => sum + item.total_consumption,
          0,
        );
      } else {
        newChartLines = dataConsumptionQuery?.barn[0]?.feed_lines.map((fl, index) => {
          return {
            linePlot: fl.feed_frame_summaries.map(apiDataToChartData),
            label: fl.name,
            colour: LINE_COLOUR_ROTATION[index % LINE_COLOUR_ROTATION.length],
            link: {
              pathname: `/b/${barnId}/line/${fl.id}`,
              state: { start: startInSeconds, end: endInSeconds },
            },
            hidden: hiddenChartLines[fl.id],
            onHideClick: (hidden) => hideChartLine(fl.id, hidden),
          };
        });
        feedUsage = dataConsumptionQuery?.barn[0]?.feed_lines.reduce((sum, item) => {
          console.log(hiddenChartLines);
          return (
            sum +
            (hiddenChartLines[item.id]
              ? 0
              : item.feed_frame_summaries.reduce((sum, item) => sum + item.total_consumption, 0))
          );
        }, 0);
      }
    }
  }

  // Create a dictionary of feed line names for this barn.
  // Also create a list of fault events associated with feed line IDs, sorted by startedAt time.

  const trackedEvents = {};

  const { feedLineNamesById = {}, events = [] } =
    dataFaultQuery?.feed_line?.reduce(
      ({ feedLineNamesById, events }, { id: feedLineId, name, device_assignments }) => {
        // Adds this feed line's 'id : name' as a 'key : value' pair to this dictionary.
        feedLineNamesById[feedLineId] = censor(name, censorTypes.feedline);
        // Adds all events for this feed line to this array, then sorts the array by 'startedAt'.
        events = (
          device_assignments?.reduce((events, deviceAssignment) => {
            deviceAssignment?.device?.faults
              ?.filter((fault) => {
                // Filtering here instead of a 2nd query due to simplicity.
                // Faults belong to devices, device are assigned. We were simply taking the faults that _existed_ during
                // the specified time range though they may have been assigned elsewhere during the time range.

                // This now filters to make sure that the fault occurred during this assignment's active period
                return (
                  // fault must have started between the device start and end OR the device is still assigned to this pipe
                  // The <= is on both for edge cases of an instantaneous event
                  deviceAssignment.started_at <= fault.started_at &&
                  (!deviceAssignment.ended_at || fault.started_at <= deviceAssignment.ended_at) &&
                  // and either the fault hasn't ended yet or it ended before the device assignment ended (if it's ended)
                  (!fault.ended_at || !deviceAssignment.ended_at || fault.ended_at <= deviceAssignment.ended_at)
                );
              })
              ?.reduce((events, { code, ended_at, fault_comments, id, nofeed_seconds, started_at }) => {
                const { comment: notes = '', fault_root_cause_id: rootCauseId = null } = fault_comments?.[0] ?? {};
                if (!trackedEvents[id]) {
                  // Ongoing (unended) events will be selected and expanded by default.

                  events.push({
                    barnId,
                    code,
                    endedAt: ended_at,
                    feedLineId,
                    id,
                    lotId: '',
                    nofeedSeconds: nofeed_seconds,
                    notes,
                    room: '',
                    rootCauseId,
                    startedAt: started_at,
                  });
                  trackedEvents[id] = true;
                }
                return events;
              }, events);
            return events;
          }, events) || []
        ).sort((a, b) => {
          if (a.endedAt === null && b.endedAt !== null) {
            return -1;
          } else if (a.endedAt !== null && b.endedAt === null) {
            return 1;
          }
          return b.startedAt - a.startedAt;
        });
        return {
          feedLineNamesById,
          events,
        };
      },
      {
        feedLineNamesById: {},
        events: [],
      },
    ) || {};

  useEffect(() => {
    console.log(events);
  }, [events]);

  // Maps feed line IDs to the numerical order they would appear if sorted by name, ascending from 0.
  const feedLineOrder = Object.keys(feedLineNamesById).reduce((feedLineOrder, feedLineId, index) => {
    feedLineOrder[feedLineId] = index;
    return feedLineOrder;
  }, {});

  barnName = dataFaultQuery?.farm?.[0]?.name || 'FeedFlo Data';

  /**
   * Deselects the event with given 'id', and selects the next expanded event, if any.
   *
   * @param id The id of the event we want to deselect.
   */
  const deselectEvent = useCallback(
    /**
     * @param {string} id
     */
    (id) => {
      // Remove the id from the expanded events dictionary.
      const updatedExpandedEvents = cloneDeep(expandedEvents);
      delete updatedExpandedEvents[id];
      setExpandedEvents(updatedExpandedEvents);
    },
    [expandedEvents],
  );

  /**
   * Deselects all events.
   */
  const deselectAllEvents = useCallback(
    /**
     * @param {string} id
     */
    () => setExpandedEvents({}),
    [],
  );

  /**
   * Selects the event with given 'id', and deselects the previously selected event, if any.
   *
   * @param id The id of the event we want to select.
   */
  const selectEvent = useCallback(
    /**
     * @param {string} id
     */
    (id) => {
      // Add the id to the expanded events dictionary.
      const updatedExpandedEvents = cloneDeep(expandedEvents);
      updatedExpandedEvents[id] = true;
      setExpandedEvents(updatedExpandedEvents);
    },
    [expandedEvents],
  );

  /**
   * Updates 'hiddenChartLines' state for a given feed line ID with a given value.
   *
   * @param feedLineId The feed line ID of the line chart we want to hide or reveal.
   * @param hidden The new value. 'true' for hidden, 'false' for revealed.
   */
  const hideChartLine = useCallback(
    /**
     * @param {number} feedLineId
     * @param {boolean} hidden
     */
    (feedLineId, hidden) => {
      const hiddenChartLinesUpdated = cloneDeep(hiddenChartLines);
      hiddenChartLinesUpdated[feedLineId] = hidden;
      setHiddenChartLines(hiddenChartLinesUpdated);
    },
    [hiddenChartLines],
  );

  /**
   * Updates 'hiddenChartLines' state to 'false' for all feed line IDs.
   * Useful for resetting hidden status when changing view selection.
   */
  const revealAllChartLines = useCallback(() => {
    const hiddenChartLinesUpdated = Object.keys(hiddenChartLines).reduce((hiddenChartLines, feedLineId) => {
      hiddenChartLines[feedLineId] = false;
      return hiddenChartLines;
    }, {});
    setHiddenChartLines(hiddenChartLinesUpdated);
  }, [hiddenChartLines]);

  /**
   * Toggles 'projectionMode' between boolean states.
   *
   * When enabling projection mode when on feed line view, we want to hide every chart line but one.
   */
  const toggleChartProjectionMode = useCallback(() => {
    setProjectionMode(!projectionMode);
  }, [projectionMode]);

  const showCumulative = useCallback(() => {
    // Disable the projection mode if switching to daily view.
    setCumulative(true);
  }, [cumulative]);

  const showDaily = useCallback(() => {
    setProjectionMode(false);
    setCumulative(false);
  }, [cumulative]);

  /**
   * Callback function passed to view selection drop-down.
   *
   * When enabling feed line view when chart projections are enabled, we want to hide every chart line but one.
   */
  const toggleSelectedView = useCallback(
    (value) => {
      setSelectedView(value.name);
      // Turn off hiding of all chart lines and event points.
      revealAllChartLines();
    },
    [revealAllChartLines],
  );

  /**
   * Given an event/fault code, determine the appropriate event category.
   *
   * @param eventCode The event/fault code in question.
   *
   * @returns The event category from the ConsumpionTabEventCategory enum.
   */
  const getEventCategory = useCallback(
    /**
     * @param {number} eventCode
     */
    (eventCode) => {
      if (eventCode === FaultCodes.EMPTY_PIPE) {
        return ConsumptionTabEventCategory.EmptyPipe;
      }

      if (eventCode === FaultCodes.INACTIVE_AUGER) {
        return ConsumptionTabEventCategory.InactiveAuger;
      }

      if (eventCode === FaultCodes.SUDDEN_CONSUMPTION_DROP || eventCode === FaultCodes.CONSUMPTION_TRENDING_DOWN) {
        return ConsumptionTabEventCategory.ChangeInConsumption;
      }

      // TODO: when implementing custom events. If the event is a custom event return ConsumptionTabEventCategory.CustomEvent.
      return null;
    },
    [],
  );

  const getCustomEventType = useCallback(() => {
    // TODO: when implementing custom events. If the event is a custom event include the ConsumptionTabCustomEventType enum in this file and return the corresponding custom type.
    return null;
  }, []);

  /**
   * Callback passed to EventAccordion events used to insert 'note' as a fault_comment.
   */
  const saveEventNote = useCallback((faultId, rootCauseId, note) => {
    saveFaultComment({
      variables: {
        object: {
          fault_id: faultId,
          fault_root_cause_id: rootCauseId,
          comment: note,
        },
      },
      onError: (error) => console.error(`Error saving note: ${error}`),
    });
  }, []);

  const feedLineRowData = useMemo(
    () =>
      Object.entries(feedLineOrder).reduce((feedLineData, [id, row], index) => {
        feedLineData[row] = {
          id,
          label: feedLineNamesById[id],
          colour: LINE_COLOUR_ROTATION[index % LINE_COLOUR_ROTATION.length],
          hidden: hiddenChartLines[id],
          reveal: () => hideChartLine(id, false),
        };
        return feedLineData;
      }, []),
    [feedLineNamesById, feedLineOrder, hiddenChartLines, hideChartLine],
  );

  // Collect all relevant state data into two lists.
  // 'accordionEvents' contains all the info needed to display and control the accordion list.
  // 'chartEvents' contains all the info needed to display and control events on the chart.
  const { accordionEvents, chartEvents } = useMemo(() => {
    const accordionEvents = events.reduce((accordionEvents, event) => {
      const category = getEventCategory(event.code);
      const customEventType = getCustomEventType(event.code);
      const expanded = true === expandedEvents[event.id];

      accordionEvents.push({
        id: event.id,

        left: makeEventIcon(category, customEventType, false),
        content: makeEventContent(
          getFaultName(event.code),
          event.startedAt,
          event.endedAt,
          event.nofeedSeconds,
          expanded,
        ),

        category,
        details: feedLineNamesById[event.feedLineId],
        expanded,
        hidden: hiddenChartLines[event.feedLineId],

        linkText: user?.isStaff ? 'View Fault' : null,
        linkTo: user?.isStaff ? `/fault/${event.id}` : null,
        notes: event.notes,

        onPanelClick: () => {
          const isExpanded = expandedEvents[event.id] ?? false;
          if (isExpanded) {
            deselectEvent(event.id);
          } else {
            selectEvent(event.id);
          }
        },
        saveNote: (note) => {
          saveEventNote(event.id, event.rootCauseId, note);
        },
      });
      return accordionEvents;
    }, []);

    const chartEvents = events.reduce((eventPoints, { code, endedAt, feedLineId, id, startedAt }) => {
      const category = getEventCategory(code);
      const hidden = !visibleCategories[category];
      if (!hidden) {
        const selected = true === expandedEvents[id];
        eventPoints.push({
          colour: EVENT_COLOURS[getEventCategory(code)],
          // 'startedAt' and 'endedAt' timestamps in seconds.
          //   If 'endedAt' is null, we use the current timestamp instead.
          points: [startedAt, endedAt ? endedAt : dayjs.tz().unix()],
          row: feedLineOrder[feedLineId] || 0,
          selected,
          onClick: () => {
            if (selected) {
              deselectEvent(id);
            } else {
              selectEvent(id);
              setScrollToEventId(id);
            }
          },
        });
      }
      return eventPoints;
    }, []);

    return { accordionEvents, chartEvents };
  }, [events, expandedEvents, hiddenChartLines, visibleCategories, deselectEvent, selectEvent]);

  return (
    <div className="ConsumptionDisplay">
      <div className="ConsumptionDisplay-chartContainer">
        <div className="ConsumptionDisplay-topBar">
          <div className="ConsumptionDisplay-dateAndDropdownContainer">
            <DateRangePicker
              className="dateRangeButton"
              visible={dateRangePickerVisible}
              dateRange={dateRange}
              setVisible={setDateRangePickerVisible}
              setDateRange={setDateRange}
            />
            <FeedFloDropDown
              className="feedFloDropDown"
              defaultTitle={DEFAULT_VIEW_OPTION}
              list={VIEW_OPTION_LIST}
              onChange={toggleSelectedView}
            />
            <SectionalButton
              sections={[
                { content: 'Cumulative', active: cumulative, onClick: showCumulative },
                { content: 'Daily', active: !cumulative, onClick: showDaily },
              ]}
            />
            <QuickExportButton barnId={barnId} barnName={barnName} dateRange={dateRange} />
          </div>
          <div className="ConsumptionDisplay-chartButtonsContainer">
            {/** TODO: When implementing custom event creation, add functionality to this button */}
            <Button
              className="ConsumptionDisplay-chartOptionButton--disabled ConsumptionDisplay-chartOptionButton"
              content="Add Event"
            />

            <Button
              className={`ConsumptionDisplay-chartOptionButton ${
                projectionMode ? 'ConsumptionDisplay-chartOptionButton--selected' : ''
              }`}
              content="Projection Range"
              disabled={!cumulative}
              onClick={toggleChartProjectionMode}
            />
          </div>
        </div>
        <div className="ConsumptionDisplay-body">
          <div className="charts">
            <div className="title">Feed Usage</div>
            {loadingFaultQuery || loadingConsumptionQuery ? (
              <LoadingSkeleton className="ConsumptionDisplay-loading" />
            ) : (
              <div>
                {convertGramsToLargeUnits(isMetric, feedUsage)} <span>{weightLargeUnitLabel(isMetric)}</span>
              </div>
            )}
            <EventChart
              start={startInSeconds}
              end={endInSeconds}
              chartLines={newChartLines}
              events={chartEvents}
              rows={feedLineRowData}
              inspectionWindow={projectionMode ? { lineOfBestFit: true } : null}
              snapToPoints={!cumulative}
              isMetric={isMetric}
              chartHeight={chartHeight}
              chartMargin={CHART_MARGIN}
              loading={loadingFaultQuery || loadingConsumptionQuery}
            />
          </div>
          <div
            className="ConsumptionDisplay-chartOptionButton ConsumptionDisplay-deselectAllEventsButton"
            style={{
              right: CHART_MARGIN.right,
              top: chartHeight,
            }}
            onClick={deselectAllEvents}
          >
            Deselect All Events
          </div>
        </div>
      </div>
      <div className="ConsumptionDisplay-eventAccordionContainer">
        <EventAccordion
          events={accordionEvents}
          categories={EVENT_CATEGORIES}
          visibleCategories={visibleCategories}
          setVisibleCategories={setVisibleCategories}
          scrollToEventId={scrollToEventId}
          selectedEventIds={openFaultIds}
          loading={loadingFaultQuery}
        />
      </div>
    </div>
  );
}

ConsumptionDisplay.propTypes = {
  barnId: PropTypes.string,
  animalGroupDateRange: PropTypes.object,
  openFaultIds: PropTypes.arrayOf(PropTypes.string),
  visibleFeedLineIds: PropTypes.arrayOf(PropTypes.string),
};

export default ConsumptionDisplay;
