import { useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { gql, useQuery, useMutation } from '@apollo/client';
import Page from '../../atoms/Page';
import CalibrationCard from '../../organisms/CalibrationCard/CalibrationCard';
import './CalibrationTab.scss';
import Button from '../../atoms/Button';
import BinCheckDialog from '../../organisms/BinCheckDialog';
import { InsertBinSetLevelMutation } from '../../organisms/CalibrationCard/queries';
import { toast } from 'react-toastify';
import FeedFloErrorBoundary from '../../organisms/FeedFloErrorBoundary';

// Ordering could also be done client-side, but given this is only a few items this aggregate is still fairly simple
const BIN_SET_QUERY_GQL = gql`
  query BinSetQuery($barn_id: uuid!) {
    bin_set(where: { farm_id: { _eq: $barn_id } }, order_by: { bins_aggregate: { min: { name: asc } } }) {
      id
      bins {
        name
        bin_feed_lines {
          feed_line {
            id
            name
            device_assignments(where: { ended_at: { _is_null: true } }) {
              device {
                label
              }
            }
          }
        }
      }
    }
  }
`;

function CalibrationTab({ titleSegments = [], barnId = '' }) {
  const { error, loading, data } = useQuery(BIN_SET_QUERY_GQL, {
    variables: {
      barn_id: barnId,
    },
  });
  const pageTitleSegments = useMemo(() => ['Calibration', ...titleSegments], []);
  const dialogRef = useRef(null);
  const firstFieldRef = useRef(null);

  const [insertBinSetLevel] = useMutation(InsertBinSetLevelMutation);

  const onInsertBinSetLevel = (
    occurredAtInSeconds,
    levelInGrams,
    binSetID,
    source,
    method,
    purpose,
    expectedDeviationInGrams,
    notes,
  ) => {
    insertBinSetLevel({
      variables: {
        object: {
          valid_at: occurredAtInSeconds,
          level_in_grams: levelInGrams,
          bin_set_id: binSetID,
          source,
          method,
          purpose: purpose,
          expected_deviation_in_grams: expectedDeviationInGrams,
          comment: notes,
        },
      },
      refetchQueries: [
        'BinCalibration_BinChecks',
        'BinCalibration_BinSetLevelQuery',
        'CalibrationCard_GetBinSetCalibrations',
        'BinInventory_BinSetLevelQuery',
      ],
      onCompleted: () => {
        toast('Bin Check Inserted', {
          position: 'top-right',
          autoClose: 3000,
          closeOnClick: true,
        });
      },
    });
  };

  if (loading) {
    return <span>Loading...</span>;
  }

  if (error) {
    return <span>{JSON.stringify(error)}</span>;
  }

  return (
    <Page className="CalibrationTab" titleSegments={pageTitleSegments}>
      {data?.bin_set?.map((binSet) => {
        const feedlines = [];
        const feedlineIds = [];

        for (const bin of binSet.bins || []) {
          for (const binFeedLine of bin.bin_feed_lines || []) {
            if (!feedlineIds.includes(binFeedLine.feed_line.id)) {
              feedlines.push(binFeedLine.feed_line);
              feedlineIds.push(binFeedLine.feed_line.id);
            }
          }
        }

        return (
          <FeedFloErrorBoundary key={binSet?.id}>
            <CalibrationCard key={binSet?.id} binSetID={binSet?.id} feedlines={feedlines} />
          </FeedFloErrorBoundary>
        );
      })}

      <Button
        content="Add Bulk Bin Checks"
        onClick={() => {
          if (!dialogRef?.current || !firstFieldRef?.current) {
            return;
          }

          dialogRef.current.showModal();
          firstFieldRef.current.focus(); // Focus on the first element in the form when the dialog opens
        }}
      />

      <BinCheckDialog
        dialogRef={dialogRef}
        firstFieldRef={firstFieldRef}
        title="Create Bin Check"
        binSets={data?.bin_set}
        newEntry={true}
        onInsertBinSetLevel={onInsertBinSetLevel}
      />
    </Page>
  );
}

CalibrationTab.propTypes = {
  titleSegments: PropTypes.arrayOf(PropTypes.string),
  barnId: PropTypes.string,
};

export default CalibrationTab;
